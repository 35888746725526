.App {
}

.content {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  margin: 20px 20px 60px 20px;
  justify-content: center;
}

div .navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffae89;
}