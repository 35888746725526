.main {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
}

.match {
    width: 400px;
    margin: 20px;
}

.matchPlayer {
    display: flex;
}

.sets{
    display: flex;
    margin-left: auto;
}

.setPoints {
    width: 30px;
    text-align: center;
}

.matchHeader {
    display: flex;
}

.image {
    width: 160px;
    padding: 10px;
}

.ball {
    width: 15px;
}