.main {
    width: 300px;
    padding: 10px;
    margin: 10px;
}

.line {
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 17px;
}

.rank {
    width: 40px;
}

.name {
    flex: auto;
}

.rating {
    width: 40px;
    flex: initial;
}

.diff {
    width: 30px;
    flex: initial;
    font-size: small;
}

.image {
    width: 30px;
    margin: 0 5px 0 0;
}

.graphButton :global .MuiSvgIcon-root{
    width: .8em;
}

.graphButton:global.MuiIconButton-root{
    padding: 0px;
}